<template>
  <!--begin::Mixed Widget 18-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          {{ $t('dashboard.meeting_room.title') }}
          <div v-if="loaded && cnt > 0" class="font-size-sm text-muted">{{ $t('dashboard.meeting_room.period', { from: ts_start, to: ts_end }) }}</div>
          <PuSkeleton v-if="!loaded" />
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body Active-->
    <div v-if="cnt || !loaded" class="card-body d-flex flex-column flex-grow pt-0">
      <!--begin::Short Info-->
      <div class="card-body-content">
        <p class="mb-1">{{ $t('dashboard.meeting_room.used') }}:</p>
        <h2 v-if="loaded" class="text-secondary text-muted font-weight-bolder">
          <font-awesome-icon icon="clock" class="icon-md mr-1" />
          {{ $t('dashboard.meeting_room.used_value', {hours: cnt_used.hours, minutes: cnt_used.minutes}) }}
        </h2>
        <PuSkeleton v-else />
        <p class="mt-5 mb-1">{{ $t('dashboard.meeting_room.left') }}:</p>
        <h2 v-if="loaded" class="font-weight-bolder">
          <font-awesome-icon icon="clock" class="icon-md mr-1 text-primary"/>
          {{ $t('dashboard.meeting_room.left_value', {hours: cnt.hours, minutes: cnt.minutes}) }}
        </h2>
        <PuSkeleton v-else />
      </div>
      <!--end::Short Info-->
      <!--begin::Items-->
<!--      <div v-if="meeting_rooms.length > 0 || !loaded" class="mt-3 position-relative zindex-0">-->
<!--        <p>{{ $t('dashboard.meeting_room.for_booking') }}:</p>-->
<!--        &lt;!&ndash;begin::Widget Item&ndash;&gt;-->
<!--        <div v-if="loaded">-->
<!--          <div class="d-flex flex-nowrap align-items-center overflow-hidden mb-5" v-for="item in meeting_rooms.slice(0, 5)" :key="item.id">-->
<!--            <div class="symbol symbol-40 flex-shrink-1 mr-3">-->
<!--              <div class="symbol-label" :style="`background-image: url('${$photoUrl(item.photo, 100)}')`"></div>-->
<!--            </div>-->
<!--            <div class="d-flex flex-column flex-shrink-0 my-lg-0 my-2 mr-2">-->
<!--              <div class="white-space-nowrap">-->
<!--                <router-link :to="'/services/rooms/?id=' + item.id" class="text-dark text-hover-dark-50 font-weight-bolder mb-1 font-size-lg mr-2">{{ item.name }}</router-link>-->
<!--                <font-awesome-icon icon="caret-right" class="text-muted mr-2"/>-->
<!--                <router-link :to="'/spaces/' + item.location.id" class="text-success font-weight-bolder">{{item.location_name}}</router-link>-->
<!--              </div>-->
<!--              <div class="text-muted font-weight-bolder">{{ $t('dashboard.meeting_room.left_value', {hours: isNaN(item.cnt.hours) ? 0 : item.cnt.hours, minutes: isNaN(item.cnt.minutes) ? 0 : item.cnt.minutes}) }}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="!loaded" class="d-flex flex-nowrap align-items-center overflow-hidden mb-5">-->
<!--          <div class="symbol symbol-40 flex-shrink-1 mr-3">-->
<!--            <div class="symbol-label"><PuSkeleton></PuSkeleton></div>-->
<!--          </div>-->
<!--          <div class="d-flex flex-column flex-shrink-0 my-lg-0 my-2 mr-2">-->
<!--            <div class="white-space-nowrap">-->
<!--              <span class="text-dark text-hover-dark-50 font-weight-bolder mb-1 font-size-lg mr-2"><PuSkeleton width="50px"></PuSkeleton></span>-->
<!--              <font-awesome-icon icon="caret-right" class="text-muted mr-2"/>-->
<!--              <span class="text-success font-weight-bolder"><PuSkeleton width="50px"></PuSkeleton></span>-->
<!--            </div>-->
<!--            <div class="text-muted font-weight-bolder"><PuSkeleton width="100px"></PuSkeleton></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Widget Item&ndash;&gt;-->
<!--      </div>-->
      <!--end::Items-->
      <router-link to="/services/rooms" class="btn btn-block btn-light-primary mt-auto">
        {{ $t('dashboard.meeting_room.all_meeting_rooms') }}
      </router-link>
    </div>
    <!--end::Body Active-->
    <!--begin::Body Inactive-->
    <div v-else class="card-body d-flex flex-column flex-grow pt-0">
      <p v-html="$t('dashboard.meeting_room.inactive_1')"></p>
      <router-link to="/services" class="btn btn-block btn-light-primary">
        {{ $t('dashboard.meeting_room.buy_tariff') }}
      </router-link>
      <div class="mt-auto">
        <p class="m-0">{{ $t('dashboard.meeting_room.inactive_2') }}</p>
        <router-link to="/services/rooms" class="btn btn-block btn-primary">
          {{ $t('dashboard.meeting_room.all_meeting_rooms') }}
        </router-link>
      </div>
    </div>
    <!--end::Body Inactive-->
  </div>
  <!--end::Mixed Widget 18-->
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import moment from 'moment'
import api from "@/plugins/api";

export default {
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      packages: 'dashboard/packages',
    }),
  },
  data: () => {
    return {
      ts_start: null,
      ts_end: null,
      cnt_used: 0,
      cnt: 0,
      meeting_rooms: [],
      loaded: false,
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('dashboard', [
      'PackagesInit'
    ]),
  },
  watch: {
    packages: {
      handler() {
        let self = this
        if (this.packages.length > 0) {
          this.ts_start = moment.unix(this.packages[0].ts_start).format('DD.MM.YYYY')
          this.ts_end = moment.unix(this.packages[0].ts_end).format('DD.MM.YYYY')

          let cnt_used = 0;
          let cnt = 0;
          let resource_ids = [];
          let rate_cnt = {};
          let rate_cnt_used = {};
          for (let i in this.packages) {
            if (this.packages[i].rate.length > 0) {
              for (let r in this.packages[i].rate) {
                if (this.packages[i].rate[r].rate.type == 'meeting_room') {
                  cnt_used = cnt_used + this.packages[i].rate[r].cnt_used || 0;
                  cnt = cnt + this.packages[i].rate[r].cnt || 0;

                  if (rate_cnt[this.packages[i].rate[r].rate.id] == undefined) {
                    rate_cnt[this.packages[i].rate[r].rate.id] = 0
                  }
                  if (rate_cnt_used[this.packages[i].rate[r].rate.id] == undefined) {
                    rate_cnt_used[this.packages[i].rate[r].rate.id] = 0
                  }
                  rate_cnt[this.packages[i].rate[r].rate.id] = rate_cnt[this.packages[i].rate[r].rate.id] + this.packages[i].rate[r].cnt || 0;
                  rate_cnt_used[this.packages[i].rate[r].rate.id] = rate_cnt_used[this.packages[i].rate[r].rate.id] + this.packages[i].rate[r].cnt_used || 0;

                  resource_ids = resource_ids.concat(this.packages[i].rate[r].rate.resource_ids);

                  // Пока закомментрирую, т.к логика неверная, нужно сначала собрать все типы переговорок
                  // досутпные для бронирования, а потом получить с сервера все переговорки по этим типам
                  // this.meeting_rooms.push({
                  //   id: this.packages[i].rate[r].id,
                  //   name: this.packages[i].rate[r].rate.name,
                  //   photo: this.packages[i].rate[r].rate.photos.length > 0 ? this.packages[i].rate[r].rate.photos[0].url : null,
                  //   cnt: {
                  //     hours: Math.floor(this.packages[i].rate[r].cnt / 3600),
                  //     minutes: Math.floor(this.packages[i].rate[r].cnt % 3600 / 60),
                  //     seconds: this.packages[i].rate[r].cnt % 60,
                  //   },
                  //   location: this.packages[i].order.location,
                  //   location_name: this.packages[i].order.location.name,
                  //   location_link: this.packages[i].order.location.name,
                  //   location_booking: this.packages[i].rate[r].id,
                  // })
                }
              }
            }
          }

          resource_ids = resource_ids.reverse().filter(function (e, i, arr) {
            return arr.indexOf(e, i+1) === -1;
          }).reverse();

          api.get('/v2/meeting-rooms', {
            // 'ids': responce.data.location_ids.join(','),
            'ids': resource_ids.join(','),
            'page': 1,
            'per-page': 200,
            'visible': 1,
            'sort': 'id',
            'expand': 'coworking,location'
          }).then(responce => {
            for (let i in responce.data) {
              self.meeting_rooms.push({
                id: responce.data[i].id,
                name: responce.data[i].name,
                photo: responce.data[i].photos.length > 0 ? responce.data[i].photos[0] : null,
                cnt: {
                  hours: Math.floor(rate_cnt[responce.data[i].rate.id] / 3600),
                  minutes: Math.floor(rate_cnt[responce.data[i].rate.id] % 3600 / 60),
                  seconds: rate_cnt[responce.data[i].rate.id] % 60,
                },
                location: responce.data[i].location,
                location_name: responce.data[i].location.name,
                location_link: responce.data[i].location.id
              })
            }
            self.loaded = true
          })

          this.cnt_used = {
            hours: Math.floor(cnt_used / 3600),
            minutes: Math.floor(cnt_used % 3600 / 60),
            seconds: cnt_used % 60,
          }
          this.cnt = {
            hours: Math.floor((cnt - cnt_used) / 3600),
            minutes: Math.floor((cnt - cnt_used) % 3600 / 60),
            seconds: (cnt - cnt_used) % 60,
          }
        }
        else {
          self.loaded = true
        }
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
  .card-custom {
    height: 304px!important;
  }
  .card-body-content {
    overflow-y: scroll!important;
  }
  @media (max-width: 991px) {
    .card-custom {
      height: 343px!important;
    }
  }
</style>
